/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  var SiteAjax = {
    getProject: function(id){

      args = { 'project_id' : id };
      $.ajax({
          url: SageAjax.ajaxurl,
          type: 'post',
          data: {
            action: 'sage_ajax_get_project',
            query_vars: JSON.stringify(args)
          },
          beforeSend: function(){
            // SiteAjax.clearVisible();
          },
          success: function( result ) {
            Site.smoothScrollTo('#project-details', 150);
            SiteAjax.clearVisible(result);
            SiteAjax.updatehistory();
          }
      });

    },
    clearVisible: function(html){

      $('#project-details').find('.project').remove();
      $('#project-details').html(html);
      $('#project-details').find('.cycle-slideshow').cycle();
      Site.bindProjectGallery();
      Site.bindMainGallery();
      
    },
    updatehistory: function(){
      if ( Site.nav === true ) {
        
        Site.nav = false;
      }else{
        project = $('#project-details .project').data('name');
        url = window.location.pathname + '?portfolio='+project;
        data = $('#project-details .project').attr('id');
        history.pushState(data, null, url);
      }
      
    },

    init:  function(){

    }
  };

  var Site = {
      log: false,
      nav: false,
      read: function(msg){

        if(msg === undefined) {
          msg = "Logging Enabled";
        }

        if (Site.log === true){
          console.log(msg);
        }
        
      },

      colorize: function(){
        $('.colorize').each(function(i){
          classes = $(this).attr('class');
          classArray = classes.split(' ');
          findText = 'text-';
          colorText = [];
          $el = $(this);

          for (var j = classArray.length - 1; j >= 0; j--) {
            text = classArray[j];
            if ( text.startsWith(findText) ) {
              colorText.push( classArray[j].replace(findText , '') );
            }
          }

          for (var k = colorText.length - 1; k >= 0; k--) {
            
            elHtml = $el.find('a').html();
            $el.find('a').html( Site.setSpan(elHtml,colorText[k]) );
            
          }

        });
      },

      setSpan: function(html, colorText){
        return html.replace( colorText  , '<span class="color">'+colorText+'</span>');
      },
   
      bindPortfolioFilters: function(){
        $('.portfolio-filters').on('click', 'a', function(e){
          e.preventDefault();
          filter =  $(this).data('filter');
          if (filter === 'all') {
            $('.project-grid .project').addClass('no-filter').fadeIn();

            $('.portfolio-filters a.active').removeClass('active');
            $(this).addClass('active');
            $('.project-grid .project.active').removeClass('active');
            $('.project-grid .project').first().addClass('active');
            goTo = $('.project-grid .project.active').data('project-id');
            // $('#project-details').cycle('goto', goTo);
            // SiteAjax.getProject(goTo);

          } else{

            $('.portfolio-filters a.active').removeClass('active');
            $(this).addClass('active');
            $('.project-grid .project').removeClass('no-filter').hide();
            $('.project-grid .project.active').removeClass('active');
            $('.project-grid .project.' + filter).addClass('filtered').fadeIn();  
            $('.project-grid .project.' + filter).first().addClass('active');
            goTo = $('.project-grid .project.active').data('project-id');
            // $('#project-details').cycle('goto', goTo);
            // SiteAjax.getProject(goTo);
          }
          
        });
      },

      bindPortfolioNav: function(){
        $('.portfolio-nav').on('click', 'a.prev', function(e){
          e.preventDefault();
          Site.read('Get Previous Project');
          Site.getSiblingProject('prev');
        });

        $('.portfolio-nav').on('click', 'a.next', function(e){
          e.preventDefault();
          Site.read('Get Next Project');
          Site.getSiblingProject('next');
        });

      },

      getSiblingProject: function(dir){
          Site.read('Get Sibling Project ' + dir);

        $active = $('.project-grid .active');
        goTo = 0;

        if (dir === 'prev') {
          $sibling = $active.prevAll('.project:visible').first();
          if ( $sibling.length ) {

          }else{
            $sibling = $('.project-grid .project:visible').last();
          }
        } else if(dir === 'next'){
          $sibling = $active.nextAll('.project:visible').first();
          if ( $sibling.length ) {

          }else{
            $sibling = $('.project-grid .project:visible').first();
          }
        }

        $('.project-grid .active').removeClass('active');
        $sibling.addClass('active');

        goTo = $sibling.data('project-id');

        SiteAjax.getProject(goTo);

      },

      bindPortfolioGrid: function(){

        $('.project-grid').on('click', '>div', function(e){
          id = $(this).data('project-id');
          $('.project-grid .active').removeClass('active');
          $(this).addClass('active');
          SiteAjax.getProject(id);
        });


      },

      bindProjectGallery: function(){
        $('.thumbnails').on('click', 'div', function(e){
          e.preventDefault();
          // get image id[zero based], get the parent, get the sibling slider, slider goto
          image = $(this).data('image');
          $parent = $(this).closest('.thumbnails');
          $slider = $parent.prev('.main-gallery');
          $slider.cycle('goto', image);

        });
      },

      bindMainGallery: function(){

        $( '.main-gallery' ).on( 'cycle-before', function( event, optionHash, outgoingSlideEl, incomingSlideEl, forwardFlag ) {
            goTo = 0;
            slideNum = optionHash.slideNum;
            n = slideNum / 6 ;

            if ( n % 1 !== 0  ) {
              // decimal
              goTo = Math.floor(n);
            } else{
              // whole
              goTo = n - 1;
            }

            $(this).next('.thumbnails').cycle('goto', goTo );

        });

      },

      smoothScroll: function(){
        /* ------------------------------------------------------------------------
          Smooth Scrolling
        ------------------------------------------------------------------------- */

        // navHeight = $('header.banner').height();
        navHeight = 77 + 40;

        $('a[href*="#"]:not([href="#"],.popup)').click(function() {
            if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
              if (target.length) {
                $('html, body').animate({
                  scrollTop: target.offset().top - navHeight
                }, 1000);
                return false;
              }
            }
          });
      },

      smoothScrollTo: function(target, offset){
        /* ------------------------------------------------------------------------
          Smooth Scrolling To
        ------------------------------------------------------------------------- */
        if(offset === undefined) {
          offset = 0;
        }

        target = $(target);

        if (target.length) {
          $('html, body').animate({
            scrollTop: target.offset().top - offset
          }, 1000);
          return false;
        }

      },
      bindScroll: function(){

        var sticky = $('header.banner'),
            stickyHeight = sticky.height(),
            offset = stickyHeight * 4,
            lastScrollTop = 0;

        if (stickyHeight == 0) {
          stickyHeight = 60;
          offset = stickyHeight * 4;
        }



        $(window).scroll(function(e){
          
          scroll = $(window).scrollTop();


          if (scroll > lastScrollTop){
               // downscroll code
               if (scroll >= offset){
                   sticky.addClass('fixed');
                   sticky.removeClass('scrollUp');
               }else if( scroll <= offset && scroll >= stickyHeight ){

                   sticky.removeClass('fixed');
               }else{
                   sticky.removeClass('scrollUp');
               }

           } else {
              // upscroll code
              if (scroll >= offset){
                  sticky.addClass('fixed');
                  sticky.removeClass('scrollUp');
              }else if( scroll <= offset && scroll >= stickyHeight ){
                  // console.log('remove fixed');
                  sticky.addClass('scrollUp').removeClass('fixed');
              }else{
                  sticky.removeClass('scrollUp');
              }
           }

           if ( scroll < offset ) {
              sticky.removeClass('fixed');
           }

          lastScrollTop = scroll;


        });


      },
      bindWindowPopState: function(){
        window.addEventListener('popstate', function(e){

            var project = e.state;

            if (project === null) {
              
            } else {

              Site.nav = true;
              Site.pageLoadWithProject();

            }

          });
      },
      pageLoadWithProject: function(){
        
        name = Site.getUrlParameter('portfolio');
        

        if ( name !== 'undefined' ) {
          $project = $('.project-grid .project[data-name='+name+']');
          id = $project.data('project-id');

          $('.project-grid .active').removeClass('active');
          $project.addClass('active');

          SiteAjax.getProject(id);
        }

        
      },

      getUrlParameter: function(sParam) {
          var sPageURL = decodeURIComponent(window.location.search.substring(1)),
              sURLVariables = sPageURL.split('&'),
              sParameterName,
              i;

          for (i = 0; i < sURLVariables.length; i++) {
              sParameterName = sURLVariables[i].split('=');

              if (sParameterName[0] === sParam) {
                  return sParameterName[1] === undefined ? true : sParameterName[1];
              }
          }
      },

      moreProjects: function(){
        $('.button.more-btn').on('click', function(e){
          e.preventDefault();
          $('.project-grid .project:hidden').fadeIn();
          $(this).hide();
        })
      },

      imageFit: function(){
        
        $('.hero-slides').height( jQuery(window).height() );
          // $('.hero-slides').maximage();
        $(window).on("resize", function(){
            $('.hero-slides').height( jQuery(window).height() );
        })
      },
   
      init: function() {
        Site.pageLoadWithProject();
        Site.colorize();
        Site.smoothScroll();
        Site.bindPortfolioFilters();
        Site.bindPortfolioGrid();
        Site.bindPortfolioNav();
        Site.bindProjectGallery();
        Site.bindMainGallery();
        Site.bindScroll();
        Site.bindWindowPopState();
        Site.moreProjects();
        Site.read();
        Site.imageFit();
      }
  };  

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        Site.init();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
